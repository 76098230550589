import axios from "./axios"




/* 用户登录相关 */

// 账号密码登录
export const signIn = (params) => axios.post("admin/data/signUp", params)
// 动登录
export const autoSignUp = (params) => axios.post("admin/data/autoSignUp", params)
// 退出登录
export const loginOut = (params) => axios.get('admin/data/loginOut',{params})
// 获取管理员信息
export const getAdminUserInfo = (params) => axios.get('admin/data/getDataUserInfo', {params})


// 组织内概况
export const getUnitSituation = (params) => axios.get('admin/data/getUnitSituation',{params})
// 党内活动统计
export const getPartyTotal = (params) => axios.get('admin/data/getPartyTotal',{params})
// 党务会议完成情况
export const getMeetingDwData = (params) => axios.get('admin/data/getMeetingDwData',{params})
// 累计数据
export const getCumulativeData = (params) => axios.get('admin/data/getCumulativeData',{params})
// 近期会议开展
export const getLatelyMeetingData = (params) => axios.get('admin/data/getLatelyMeetingData',{params})
// 党员性别以及年龄分布
export const getSexAgeTotal = (params) => axios.get('admin/data/getSexAgeTotal',{params})
// 获取优秀党员
export const getGoodUser = (params) => axios.get('admin/data/getGoodUser',{params})


// 获取活跃党支部
export const getActiveUnit = (params) => axios.get('admin/data/getActiveUnit',{params})

// 获取组织位置列表
export const getDepartmentPositionList = params => axios.get("admin/data/getDepartmentPositionList", {params});

// 先锋队组织名单
export const getVanguardRankList = params => axios.get("admin/data/getVanguardRankList", {params});

/**
 * 以上是中原党建的数据大屏接口
 * */
