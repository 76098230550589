import {} from "../../api/index"


const state = {
    type:0,

    activeName: 0,

    active_name: '2',



};

const mutations = {


    SwitchType: (state, payload) => {
        state.type = payload;
    },
    getActiveName:(state, payload) =>{
        state.activeName = payload;
    },
    get_active_name:(state, payload) =>{
        state.active_name = payload;
    },

};

const actions = {};


export default {
    state,
    mutations,
    actions
}
