import {getOrgAreaList} from "../../api"
import {isLogin} from "../../utils/auth"
import {formatAddress} from "../../utils/index"

class Address {

    state = {

        address: "-",

        cityAreaList: [],

        myAuthAreaList: [],
    }

    mutations = {

        // 格式化市区县数据
        saveCityAreaList: (state, payload) => {
            state.cityAreaList = formatAddress(payload);
        },

        // 修改当前的省市区名称
        editCityAreaName: (state, payload) => {
          state.address = payload;
        },

        // 提取我的局权限对应的市区县列表
        extractMyAuthCityList: (state, payload) => {

            state.myAuthAreaList = [];

            if(payload === 610000) {
                state.address = "陕西省";
                state.myAuthAreaList = state.cityAreaList;
                state.myAuthAreaList.unshift({
                    value: 610000,
                    label: state.address,
                })
                return;
            }

            (function findAreaData(sourceList, list) {
                for (let index = 0; index < sourceList.length; index++) {
                    if (sourceList[index].value === payload) {
                        list.push(sourceList[index]);
                        if (sourceList[index].p_id === 610000) {
                            state.address = `陕西省${sourceList[index].label}`;
                            return;
                        }
                        state.address = sourceList[index].full_name.slice(3).replace(/局/g, "");
                        return;
                    }
                    if (sourceList[index].children) {
                        findAreaData(sourceList[index].children, list);
                    }
                }
            })(state.cityAreaList, state.myAuthAreaList);
        },

        clearMyAuthCityList: state => {
            state.address = "-";
            state.myAuthAreaList = [];
        }

    }

    actions = {

        // getAreaList: async ({commit}) => {
        //     if (isLogin()) {
        //         const {data} = await getOrgAreaList({p_id: 610000});
        //         commit('saveCityAreaList', data);
        //     }
        // }

    }

}

export default new Address
