const TokenKey = "user_token"


const getToken = function() {
    return window.localStorage.getItem(TokenKey)
    // return 'U2FsdGVkX1/KLbjpOxrJkXYJ/p8CjIBj7CQfjxUSdJnszw53miZp4+qSyoTSxRN+'
}


const setToken = function(token) {
    return window.localStorage.setItem(TokenKey, token)
}


const removeToken = function() {
    return window.localStorage.removeItem(TokenKey)
}

/* ------------------------------------------------------- */

const getSessionToken = function() {
    return window.sessionStorage.getItem(TokenKey)
}

const setSessionToken = function(token) {
    return window.sessionStorage.setItem(TokenKey, token)
}


const removeSessionToken = function() {
    return window.sessionStorage.removeItem(TokenKey)
}


//判断用户是否登录
const isLogin = function() {
    const token = getToken() || getSessionToken()
    return Boolean(token)
}

export {
    TokenKey,
    getToken,
    setToken,
    removeToken,
    isLogin,
    getSessionToken,
    setSessionToken,
    removeSessionToken
}
