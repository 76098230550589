<template>
  <div class="section-title-bar">
    <div class="linear-gradient"></div>
    <span class="title">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "section-title-bar",

}
</script>

<style lang="scss" scoped>

  $bar-height: 16px;

  .section-title-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: $bar-height;

    .linear-gradient {
      width: 5px;
      height: $bar-height;
      background-image: linear-gradient(to bottom, #22d3ed, #4d79ff);
    }

    .title {
      height: 100%;
      margin-left: 12px;
      line-height: $bar-height;
      font-size: $bar-height;
      font-weight: 400;
      color: #81A9F1;
      letter-spacing: 1px;
    }
  }
</style>
