import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters"
import routine from "./modules/routine"
import user from "./modules/user"
import address from "./modules/address"
import {formatTime,toNextTimes} from '../utils/index'
import {getWeatherInfo} from "../api/axios";
import electronic from "./modules/electronic"

Vue.use(Vuex)

const store = new Vuex.Store({

    modules: {
        // 常规检查请求接口数据相关
        routine,
        // 用户信息相关
        user,
        // 获取地区数据相关
        address,

        //电子烟相关
        electronic,
    },

    state: {
        data_type: "conventional",
        current_date: "",
        current_time: "",
        update_date: "",
        weather_info: {},
    },

    mutations: {
        // 切换检查类型视图
        SWITCH_DATA_TYPE: (state, payload) => {
            state.data_type = payload
        },

        // 获取当前的时间
        GET_CURRENT_TIME: state => {
            const [year, month, day, hour, minute, second] = formatTime(new Date, 3);
            state.current_date = `${year}年${month}月${day}日`;
            state.current_time = `${hour}:${minute}:${second}`;
            state.update_date = formatTime(new Date(toNextTimes(-1)), 5);
        },

        SAVE_WEATHER_INFO: (state, payload) => {
            state.weather_info = payload;
        }

    },

    actions: {

        GET_WEATHER_INFO: async ({commit}, params) => {
            console.log(params, "天气")
            const {data} = await getWeatherInfo({city:params});
            commit("SAVE_WEATHER_INFO", data);
            // commit("SAVE_WEATHER_INFO", {});
        }

    },

    getters,

})

export default store
