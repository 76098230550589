const HTTP_TEST_URL = 'https://zy-sys.dangjianrobot.com'  // 正式服
const HTTP_TEST_URL_DEV = 'https://zy-sys-dev.dangjianrobot.com'  // 测试服

const API_BASE_URL = HTTP_TEST_URL + "/api/";
// const API_BASE_URL = HTTP_TEST_URL_DEV + "/api/";

// 天气api
const GET_WEATHER_URL = 'https://tianqiapi.com/api/'

// oss云存储地址
const OSS_URL = 'http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/'

const BAI_DU_MAP_AK = 'ozT7g2K7TQBlwdTOCKukAqTWOrXG9TCd'


export {
    HTTP_TEST_URL,
    API_BASE_URL,
    OSS_URL,
    GET_WEATHER_URL,
    BAI_DU_MAP_AK,
}
