import {
    getUnitSituation,
    getPartyTotal,
    getMeetingDwData,
    getCumulativeData,
    getLatelyMeetingData,
    getSexAgeTotal, getGoodUser, getActiveUnit,
    getVanguardRankList
} from "../../api/index"

import {Loading} from "element-ui";


const state = {

    // 党务会议完成情况的loading
    unitExamineLoading: false,

    // 党内活动统计的loading
    randomLoading: false,

    // 近期会议开展的loading
    analyseLoading: false,
    //优秀党员的loading
    GoodLoading: false,

    // 累计数据
    realTimeExamineList: [
        {icon: 'accomplish2', old_count: 0, count: 0, name: '党建课程'},
        // { icon: 'holder2',        old_count: 0, count: 0, name: '党建活动' },
        {icon: 'not-holder', old_count: 0, count: 0, name: '党建资讯'},
        {icon: 'cigar', old_count: 0, count: 0, name: '开展会议'},
        {icon: 'examine-person', old_count: 0, count: 0, name: '参与会议人数'},
        // { icon: 'examine-person', old_count: 0, count: 0, name: '' },
    ],

    // 组织内概况
    areaInsideList: [
        {icon: 'retail-sum', old_count: 0, count: 0, name: '党组织总数'},
        {icon: 'holder', old_count: 0, count: 0, name: '党员总数'},
        {icon: 'examine-person', old_count: 0, count: 0, name: '党员先锋队'},
        {icon: 'examine-person', old_count: 0, count: 0, name: '正式党员'},
        {icon: 'accomplish', old_count: 0, count: 0, name: '预备党员'}
        // { icon: 'await',          old_count: 0, count: 0, name: '流动党员' },
        // { icon: 'examine-person', old_count: 0, count: 0, name: '积极分子' },
    ],


    // 党务会议完成情况数据列表
    subordinateUnitList: [],

    // 党内活动统计数据对象
    contentStatisticsData: {},

    // 数据大屏中间三个数值
    centerTopData: {
        all_check_num: 0,
        total_check_num: 0,
        hold_cert_shop_num: 0,
    },

    // 党员性别以及年龄分布
    newExamineRecordList: [],


    // 近期会议开展数据对象
    checkStatisticsList: [],

    //地图上面的三个数据
    Profile_data: {},

    // 人员排名列表
    personRankingList: [],

    // 先锋队员列表
    pioneerList: [],
    pioneer_total: 0,
    pioneerLoading: false

};

const mutations = {
    setCheckRealTimeData: (state, payload) => {
        state.centerTopData.total_check_num = payload.total_check_num;
        state.centerTopData.all_check_num = payload.all_check_num;

        state.realTimeExamineList[0].old_count = state.realTimeExamineList[0].count
        state.realTimeExamineList[1].old_count = state.realTimeExamineList[1].count
        state.realTimeExamineList[2].old_count = state.realTimeExamineList[2].count
        state.realTimeExamineList[3].old_count = state.realTimeExamineList[3].count

        state.realTimeExamineList[0].count = payload.course_total;
        state.realTimeExamineList[1].count = payload.news_total;
        state.realTimeExamineList[2].count = payload.open_meeting_total;
        state.realTimeExamineList[3].count = payload.join_meeting_user_total;
    },

    setCheckAreaInsideData: (state, payload) => {

        state.centerTopData.hold_cert_shop_num = payload.hold_cert_shop_num;

        state.areaInsideList[0].count = payload.all_unit_total;
        state.areaInsideList[1].count = payload.all_unit_user_total;
        state.areaInsideList[2].count = 104;
        state.areaInsideList[3].count = payload.official_total;
        state.areaInsideList[4].count = payload.probationary_total;
        // state.areaInsideList[4].count = payload.flow_total;
        // state.areaInsideList[5].count = payload.activists_total;
        state.Profile_data = payload
    },


    setSubordinateUnitData: (state, payload) => {
        state.subordinateUnitList = payload;
    },

    setContentStatisticsData: (state, payload) => {
        state.contentStatisticsData = payload;
    },

    setNewExamineRecordData: (state, payload) => {
        state.newExamineRecordList = payload;
    },

    setCheckStatisticsData: async (state, payload) => {
        state.checkStatisticsList = payload;
    },


    setExamineLoading: (state, bool) => {
        state.unitExamineLoading = bool;
    },

    setRandomLoading: (state, bool) => {
        state.randomLoading = bool;
    },

    setAnalyseLoading: (state, bool) => {
        state.analyseLoading = bool;
    },
    setGoodLoading(state, bool) {
        state.GoodLoading = bool;
    },

    setPersonRankingData: (state, payload) => {
        state.personRankingList = payload;
    },

    setPioneerList: (state, list) => {
        state.pioneerList = list
    },
    setPioneerTotal: (state, number) => {
        state.pioneer_total = number
    },

    setPioneerLoading: (state, bool) => {
        state.pioneerLoading = bool
    }

};

const actions = {

    /**中原
     *累计数据
     * */
    getCheckRealTimeData: async ({commit}, params) => {
        const {data, level} = await getCumulativeData(params);
        level === 'success' && commit('setCheckRealTimeData', data);
    },

    /**中原
     * 组织内概况
     */
    getCheckAreaInsideData: async ({commit}, params) => {
        const {data, level} = await getUnitSituation(params);
        level === 'success' && commit('setCheckAreaInsideData', data);
    },


    /**中原
     *党务会议完成情况
     * */
    getCheckSubordinateUnitData: async ({commit}, params) => {
        commit('setExamineLoading', true);
        const {data, level} = await getMeetingDwData(params);
        level === 'success' && commit('setSubordinateUnitData', data);
        commit('setExamineLoading', false);
    },

    /**中原
     *党内活动统计
     * */
    getCheckContentStatisticsData: async ({commit}, params) => {
        commit('setRandomLoading', true);
        const {data, level} = await getPartyTotal(params);
        level === 'success' && commit('setContentStatisticsData', data);
        commit('setRandomLoading', false);
    },

    /**中原
     *党员性别以及年龄分布
     * */
    getNewExamineRecordData: async ({commit}, params) => {
        commit('setExamineLoading', true);
        const {data, level} = await getSexAgeTotal(params);
        level === 'success' && commit('setNewExamineRecordData', data);
        commit('setExamineLoading', false);
    },


    /**中原
     *近期会议开展
     * */
    getCheckStatisticsData: async ({commit}, params = {}) => {
        commit("setAnalyseLoading", true);
        const {data, level} = await getLatelyMeetingData(params);
        level === "success" && commit('setCheckStatisticsData', data);
        commit("setAnalyseLoading", false);
    },

    // 获取优秀人员排名 数据
    // 活跃党员名单
    getExaminePersonRankingData: async ({commit}, params) => {
        commit("setGoodLoading", true);
        // const {data, level} = await getGoodUser(params);
        const {data, level} = await getActiveUnit(params);
        level === 'success' && commit('setPersonRankingData', data);
        commit("setGoodLoading", false);
    },

    // 先锋队员名单
    getVanguardRankList: async ({commit}, params) => {
        commit("setPioneerLoading", true);
        const {data, level, page_info} = await getVanguardRankList(params);
        // level === 'success' && commit("setPioneerList",data);
        if (level === 'success') {
            commit("setPioneerTotal", page_info.total)
            commit("setPioneerList", data)
        }
        commit("setPioneerLoading", false);
    }

};


export default {
    state,
    mutations,
    actions
}
