import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import countTo from 'vue-count-to'
import sectionTitleBar from "./components/section-title-bar"
import './styles/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
// import 'lib-flexible/flexible';
import 'amfe-flexible'
import { encrypt } from "./utils/encryp";
// import { fullScreenContainer } from '@jiaminghi/data-view';

import * as api from './api/index'
import * as currency from './utils/index'
import * as auth from "./utils/auth"


Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.use(fullScreenContainer)
Vue.component('countTo', countTo);
Vue.component('SSectionTitleBar', sectionTitleBar);

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.prototype.$api = api;
Vue.prototype.$currency = currency;
Vue.prototype.$auth = auth;
Vue.prototype.$encrypt = encrypt;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
