import CryptoJS from 'crypto-js'
let keyStr = "rzkj2021CF10DOI80INS00FAYS55U1S1CC9M1GL77DS50KEYS3UC9QGo7PYX2JVA12oBCRuByAnDroIC3JXUk2Uz"


export function encrypt(text){
    var key = CryptoJS.enc.Latin1.parse('rzkj567890654321'); //为了避免补位，直接用16位的秘钥
    var iv = CryptoJS.enc.Latin1.parse('rzkj123456789012'); //16位初始向量
    var encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    });
    return encrypted.toString();
}
